/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-component-props */
import { ApartmentOutlined, FindInPageOutlined, PostAdd, SettingsOutlined } from "@mui/icons-material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleIcon from '@mui/icons-material/People';
import { ListItemText, Theme, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { REACT_ENV } from "environmentVariables";
import React from 'react';
import { useHistory, useParams } from "react-router-dom";

const drawerWidth = 210;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

export default function SidebarWrapper({ children, open, handleDrawerClose }): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const { tenantID, serverID } = useParams<{ tenantID: string, serverID: string }>();

  const destinations = ['Organizations', 'Standalone Deployments', 'User Management', 'Cloud Management', 'Data Loader', 'Documentation'];

  const handleRoute = (destination: string): void => {
    switch (destination) {
      case 'Organizations': {
        history.push('/');
        break;
      }
      case 'Standalone Deployments': {
        if (tenantID || serverID) {
          globalThis.location.replace('/servers');
        } else {
            history.push('/servers');
        }
        break;
      }
      case 'User Management': {
        if (tenantID || serverID) {
          globalThis.location.replace('/admin-page');
        } else {
            history.push('/admin-page');
        }
        break;
      }
      case 'Cloud Management': {
        history.push('/service-accounts');
        break;
      }
      case 'Data Loader': {
        window.open(REACT_ENV.REACT_APP_WEBLOADER_URL, "webloaderPopup");
        break;
      }
      case 'Documentation': {
        window.open(REACT_ENV.REACT_APP_DOCUMENTATION, "documentationPopup");
        break;
      }
      default: {
        history.push('/');
      }
    }
  };

  const switchIcons = (destination: string): React.ReactNode => {
    switch (destination) {
      case 'Organizations': {
        return <ApartmentOutlined style={{ margin: "0 16px 0 0" }} />
      }
      case 'Standalone Deployments': {
        return <span style={{ margin: "0 16px 0 0" }} className="material-icons">webhook</span>
      }
      case 'User Management': {
        return <PeopleIcon style={{ margin: "0 16px 0 0" }} />
      }
      case 'Cloud Management': {
        return  <span style={{ margin: "0 16px 0 0" }} className="material-icons-outlined">badge</span>
      }
      case 'Data Loader': {
        return <PostAdd style={{ margin: "0 16px 0 0" }} />
      }
      case 'Documentation': {
        return <FindInPageOutlined style={{ margin: "0 16px 0 0" }} />
      }
      default: {
        return <SettingsOutlined style={{ margin: "0 16px 0 0" }} />
      }
    }
  }

  return (
      <div className={classes.root}>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
            paper: classes.drawerPaper,
            }}
           >
              <div className={classes.drawerHeader}>
                  <IconButton onClick={() => handleDrawerClose(false)}>
                      {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
              </div>

              <Divider />

              <List>
                  {destinations.map((text, index) => (
                      <ListItem
                        className="leftIconMouseOver"
                        key={text}
                        onClick={() => {
                        handleRoute(text);
                      }}>
                          <ListItemIcon> { switchIcons(text) } </ListItemIcon>

                          <ListItemText primary={text} />
                      </ListItem>
                    ))}
              </List>
          </Drawer>

          <main
            className={clsx(classes.content, {
            [classes.contentShift]: open,
            })}
          >
              { children }
          </main>
      </div>
  );
}
